import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import ChineseLayout from "../../../components/ChineseLayout"
// import Inactivity from "../../../components/Inactivity"

/* Farms page, uses the Video > Rich Text > Stepped Navigation structure */

class Farms extends React.Component {
  componentDidMount() {
    // Inactivity()
  }
  render() {
    return(
      <ChineseLayout>
        <Helmet>
          <title>Meet MJN - Visit One Of Our Farms - Grazing</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -general -video">
            <div className="video-player-wrapper">
              <div className="youtube-player-wrapper">
                <iframe className="youtube-player" id="youtube-video" title="video" width="540" height="304" src="https://www.youtube.com/embed/JWLhrPnE1Xs?controls=0&modestbranding=1&playsinline=1rel=0" allow="autoplay" frameBorder="0"></iframe>
              </div>
            </div>
          </section>

          <section className="rich-text -bg-white -with-stepped">
            <nav className="breadcrumb" role="navigation">
              <Link to="/zh/event-experience" title="活动体验"> 
                <i className="fas fa-arrow-left"></i>
                活动体验
              </Link>
            </nav>
            <h2>放牧方式</h2>
            <p>奶牛一年中要花300天以上的时间在外面吃草。我们拥有世代相传的管理放牧技能，这种放牧方式使奶牛在整个放牧时间内，都可以食用到最新鲜的牧草，并且在24小时内分配到一定数量的牧草。在20到21天的时间范围内，他们都会在不同的牧场中转来转去，以确保每天都能吃到新鲜和营养牧草。</p>
          </section>

          <ol className="stepped-navigation">
            <li className="stepped-navigation__item -active"><span>放牧</span></li>
            <li className="stepped-navigation__item"><Link to="/zh/event-experience/family"></Link></li>
            <li className="stepped-navigation__item"><Link to="/zh/event-experience/milk"></Link></li>
          </ol>
        </main>
      </ChineseLayout>
    )
  }
}

export default Farms